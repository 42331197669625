import { createAction } from 'redux-actions'
/* eslint-disable import/prefer-default-export */
import { helperGet, helpers } from 'actions/api/helpers'
import createGetAction from 'actions/actionCreator'

export const ADD_HELPERS = 'ADD_HELPERS'
export const REMOVE_HELPES = 'REMOVE_HELPES'

export const getHelpers = params => async dispatch =>
  dispatch(
    createGetAction({
      action: helpers(params),
      shouldThrowError: true,
    }),
  )

export const getHelper = params => async dispatch =>
  dispatch(
    createGetAction({
      action: helperGet(params),
      // shouldThrowError: true,
    }),
  )

export const addHelpers = createAction(ADD_HELPERS)
export const removeHelpers = createAction(REMOVE_HELPES)
