import { createAction } from 'redux-actions'
import Logger from 'utils/Logger'
import BaseApi from 'actions/api/BaseApi'
import random from 'utils/random'

// ------------------------------------
// Constants
// ------------------------------------
export const SESSION_CREATE_SUCCESS = 'API/SESSION_CREATE_SUCCESS'

// ------------------------------------
// Actions (Thunks)
// ------------------------------------

export const getCaptcha = () => async dispatch => {
  try {
    const api = new BaseApi().create()
    const rslt = await dispatch(
      api({
        url: '/api/session/captcha',
        params: {
          t: random(),
        },
      }),
    )
    return rslt.captcha
  } catch (error) {
    throw error
  }
}

export const login = ({ data }) => async (dispatch, getState, { history }) => {
  Logger.info(data)
  try {
    const api = new BaseApi().create()
    const rslt = await dispatch(
      api({
        url: '/api/session/create',
        method: 'post',
        loadingTarget: 'authenticate',
        data,
      }),
    )

    dispatch(createAction(SESSION_CREATE_SUCCESS)(rslt))
    history.push('/game')
  } catch (error) {
    throw error
  }
}
