import { createAction } from 'redux-actions'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const setNotification = data => dispatch =>
  dispatch(
    createAction(SET_NOTIFICATION)({ ...data, key: new Date().getTime() }),
  )

export const removeNotification = createAction(REMOVE_NOTIFICATION)
