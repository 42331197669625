import { createAction } from 'redux-actions'
import { types } from 'constants/modal'
import random from 'utils/random'

// ------------------------------------
// Constants
// ------------------------------------
const SET_SUCCESS_MESSAGE = '@SET_SUCCESS_MESSAGE'
const SET_SYSTEM_ERROR = '@SET_SYSTEM_ERROR'
const DISMISS_MODAL = '@DISMISS_SYSTEM_MODAL'

// ------------------------------------
// Actions
// ------------------------------------
export const setSuccessMessage = createAction(SET_SUCCESS_MESSAGE, payload => ({
  ...payload,
  type: types.succes,
  id: random(),
}))

export const setSystemError = createAction(SET_SYSTEM_ERROR, payload => ({
  ...payload,
  type: types.error,
  id: random(),
}))

export const dismissSystemModal = createAction(DISMISS_MODAL)
