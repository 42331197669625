import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from 'actions/notification'
import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = []

export default handleActions(
  {
    [SET_NOTIFICATION]: (state, { payload }) => [...state, payload],
    [REMOVE_NOTIFICATION]: (state, { payload }) =>
      state.filter(s => s.key !== payload.key),
  },
  initialState,
)
