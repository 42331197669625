import { ADD_HELPERS, REMOVE_HELPES } from 'actions/helpers'
import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = []

export default handleActions(
  {
    [ADD_HELPERS]: (state, { payload }) => {
      const newHelperIds = payload.map(({ id }) => id)
      const old = state.filter(i => !newHelperIds.includes(i.id))
      return [...old, ...payload]
    },
    [REMOVE_HELPES]: (state, { payload }) => {
      const newHelperIds = payload.map(({ id }) => id)
      const old = state.filter(i => !newHelperIds.includes(i.id))
      return old
    },
  },
  initialState,
)
