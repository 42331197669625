// import locale from 'acs-translate/reducer'

import helpers from './helpers'
import loading from './loading'
import modals from './modal'
import notification from './notification'
// import selectedHelpers from './selectedHelpers'
import system from './system'
import user from './user'

export default {
  user,
  // locale,
  loading,
  modals,
  system,
  helpers,
  notification,
}
