import { setLoadingDone, setLoadingProgress } from 'actions/loading'
import axios from 'axios'

class BaseApi {
  // Promise based HTTP client
  // https://github.com/axios/axios
  create = options => {
    const instance = axios.create({
      baseURL: '/',
      timeout: 10000, // milliseconds
      responseType: 'json',
      method: 'get',
      ...options,
    })
    // https://github.com/axios/axios#axiosconfig
    return input => async dispatch => {
      const { loadingTarget } = input || {}
      try {
        if (loadingTarget) {
          dispatch(setLoadingProgress({ taget: loadingTarget }))
        }

        const rslt = await instance(input)
        const data = this.checkResponse(rslt)
        return data
      } catch (error) {
        const {
          response: { headers, status, data },
        } = error
        if (status !== 200) {
          const contentType = headers['content-type']
          if (contentType.includes('json')) {
            throw data
          }
        }
        throw error
      } finally {
        if (loadingTarget) {
          dispatch(setLoadingDone({ taget: loadingTarget }))
        }
      }
    }
  }

  checkResponse = response => {
    if (response.data && response.data.code > 0) {
      const error = new Error(response.data.message)
      error.response = response
      throw error
    }
    return response.data
  }
}

export default BaseApi
