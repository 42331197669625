import { handleActions } from 'redux-actions'
import { SESSION_CREATE_SUCCESS } from 'routes/login/actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default handleActions(
  {
    [SESSION_CREATE_SUCCESS]: (state, { payload }) => payload,
  },
  initialState,
)
