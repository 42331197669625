import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { setLoadingDone, setLoadingProgress } from 'actions/loading'
// import getSystemConfig from 'actions/system'

/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    // {
    //   path: '/login',
    //   load: () => import(/* webpackChunkName: 'login' */ './login'),
    // },
    // {
    //   path: '/typography',
    //   load: () => import(/* webpackChunkName: 'typography' */ './typography'),
    // },
    // {
    //   path: '/about',
    //   load: () => import(/* webpackChunkName: 'about' */ './about'),
    // },
    // {
    //   path: '/services',
    //   load: () => import(/* webpackChunkName: 'services' */ './services'),
    // },
    // {
    //   path: '/app-req',
    //   load: () =>
    //     import(
    //       /* webpackChunkName: 'applicationReq' */ './applicationRequirement'
    //     ),
    // },
    // {
    //   path: '/search',
    //   load: () => import(/* webpackChunkName: 'search' */ './search'),
    // },
    // {
    //   path: '/detail/:id',
    //   load: () => import(/* webpackChunkName: 'detail' */ './detail'),
    // },
    // {
    //   path: '/faq',
    //   load: () => import(/* webpackChunkName: 'qna' */ './faq'),
    // },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async config({ dispatch }) {
    // await dispatch(getSystemConfig())
    console.info('dispatch', dispatch)
  },

  async action(context) {
    // Execute each child route until one of them return the result
    const { next, route: contextRoute, store } = context

    if (contextRoute.config) {
      await contextRoute.config(store)
      // https://juejin.im/post/5d59fd7ff265da03f233cc4b
      context.route.config = undefined // eslint-disable-line no-param-reassign
    }

    const {
      store: { dispatch },
    } = context
    dispatch(setLoadingProgress())
    const route = await next()
    dispatch(setLoadingDone())

    // Provide default values for title, description etc.
    route.title = `${route.title || 'ORAS PEOPLE'}`
    route.description = route.description || ''

    return route
  },
}

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  })
}

export default routes
