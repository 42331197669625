/* eslint-disable import/prefer-default-export */
import BaseApi from 'actions/api/BaseApi'

const api = new BaseApi().create()

export const helpers = params =>
  api({
    url: '/gpi/helpers',
    loadingTarget: 'helpers',
    method: 'get',
    params,
  })

export const helperGet = id =>
  api({
    url: `/gpi/helper/${id}`,
    loadingTarget: 'helpers',
    method: 'get',
  })
